/*import "./App.css";

import { EventType } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { b2cPolicies } from "./authConfig";
import Page from "./models/Page";
// Cannot be lazy loaded
import SiteError from "./pages/SiteError";
import { updateIndexedDB } from "./redux/actions";
import store from "./redux/store";
import authenticateFetch from "./utils/authenticateFetch";
import { getIDB } from "./utils/IndexedDB";
import lazyWithRetry from "./utils/lazyWithRetry";
import resendPostRequests from "./utils/resendPostRequests";

function App() {
  const { instance } = useMsal();
  const channel1 = new BroadcastChannel("channel1");
  const [IDs, setIDs] = useState([]);

  window.addEventListener("online", function (e) {
    let length = this.localStorage.getItem("idb-length");
    if (length === null) length = 0;
    length = parseInt(length);

    store.dispatch(updateIndexedDB({ length: length }));
    if (length > 0) getIDB();

    channel1.onmessage = (e) => {
      if (IDs.includes(e.data.ID)) return;
      let tempIDs = IDs;
      tempIDs.push(e.data.ID);
      setIDs(tempIDs);
      resendPostRequests(e.data, instance);
    };
  });

  //fill API Cache
  caches
    .open("api-cache")
    .then((cache) => cache.keys())
    .then((keys) => {
      const priorityCache = ["farms", "fieldsfromid", "crops", "fertilisers"];
      let numMatches = keys.reduce((total, current) => {
        if (
          total !== priorityCache.length &&
          priorityCache.some((route) => current.url.includes(route))
        )
          return total + 1;
        return total;
      }, 0);
      if (numMatches < priorityCache.length) return true;
      return false;
    })
    .then((needFillCache) => {
      if (!needFillCache) return;
      authenticateFetch(instance, "/api/v1/database/farms", {
        method: "GET",
        headers: new Headers(),
      })
        .then((res) => res.json())
        .then((farms) => {
          farms.forEach((farm) => {
            authenticateFetch(
              instance,
              "/api/v1/database/fieldsfromid",
              {
                method: "GET",
                headers: new Headers(),
              },
              {
                farmID: farm.FarmID,
              }
            );
          });
        })
        .then(() =>
          authenticateFetch(instance, "/api/v1/database/crops", {
            method: "GET",
            headers: new Headers(),
          })
        )
        .then(() =>
          authenticateFetch(instance, "/api/v1/database/fertilisers", {
            method: "GET",
            headers: new Headers(),
          })
        );
    });

  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload.account
      ) {
        if (
          event.payload.idTokenClaims["tfp"] === b2cPolicies.names.editProfile
        ) {
          // retrieve the account from initial sign-in to the app
          const originalSignInAccount = instance
            .getAllAccounts()
            .find(
              (account) =>
                account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
                account.idTokenClaims["tfp"] === b2cPolicies.names.signUpSignIn
            );

          let signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            account: originalSignInAccount,
          };

          // silently login again with the signUpSignIn policy
          instance.ssoSilent(signUpSignInFlowRequest);
        }

        // Relogin after changing password
        if (
          event.payload.idTokenClaims["tfp"] ===
          b2cPolicies.names.forgotPassword
        ) {
          let signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
          };
          instance.loginRedirect(signUpSignInFlowRequest);
        }
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {
        // Check for forgot password error
        if (event.error && event.error.errorMessage.includes("AADB2C90118")) {
          const resetPasswordRequest = {
            authority: b2cPolicies.authorities.forgotPassword.authority,
            scopes: [],
          };
          instance.loginRedirect(resetPasswordRequest);
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  const Business = lazyWithRetry(() => import("./pages/Business"));
  const Consultant = lazyWithRetry(() => import("./pages/Consultant"));
  const Data = lazyWithRetry(() => import("./pages/Data"));
  const Dashboard = lazyWithRetry(() => import("./pages/Dashboard"));
  const Feedback = lazyWithRetry(() => import("./pages/Feedback"));
  const Main = lazyWithRetry(() => import("./pages/Main"));
  const Mixes = lazyWithRetry(() => import("./pages/Mixes"));
  const Notifications = lazyWithRetry(() => import("./pages/Notifications"));
  const Recommendation = lazyWithRetry(() => import("./pages/Recommendation"));
  const Records = lazyWithRetry(() => import("./pages/Records"));
  const Settings = lazyWithRetry(() => import("./pages/Settings"));
  const Suppliers = lazyWithRetry(() => import("./pages/Suppliers"));

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Page />,
      errorElement: <SiteError />,
      children: [
        {
          path: "/",
          element: (
            <Provider store={store}>
              <Main />
            </Provider>
          ),
        },
        {
          path: "/business",
          element: <Business />,
        },
        {
          path: "/consultant",
          element: <Consultant />,
        },
        {
          path: "/data",
          element: <Data />,
        },
        {
          path: "/dashboard",
          element: <Dashboard />,
        },
        {
          path: "/feedback",
          element: <Feedback />,
          loader: (params) => {
            const sp = new URL(params.request.url).searchParams;
            return Object.fromEntries(sp);
          },
        },
        {
          path: "/mixes",
          element: <Mixes />,
        },
        {
          path: "/notifications/:type",
          element: <Notifications />,
          loader: (params) => {
            const sp = new URL(params.request.url).searchParams;
            return Object.fromEntries(sp);
          },
        },
        {
          path: "/notifications",
          element: <Notifications />,
        },
        {
          path: "/records",
          element: <Records />,
        },
        {
          path: "/recommendation",
          element: <Recommendation />,
        },
        {
          path: "/settings",
          element: <Settings />,
        },
        {
          path: "/suppliers",
          element: <Suppliers />,
        },
      ],
    },
  ]);

  return (
    <div>
      <RouterProvider router={router}>
        <AnimatePresence />
      </RouterProvider>
    </div>
  );
}

export default App;

GP TODO GP 
*/
import "./App.css";
import { EventType } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { b2cPolicies } from "./authConfig";
import Page from "./models/Page";
import SiteError from "./pages/SiteError";
import { updateIndexedDB } from "./redux/actions";
import store from "./redux/store";
import authenticateFetch from "./utils/authenticateFetch";
import { getIDB } from "./utils/IndexedDB";
import lazyWithRetry from "./utils/lazyWithRetry";
import resendPostRequests from "./utils/resendPostRequests";

function App() {
  const { instance } = useMsal();
  const channel1 = new BroadcastChannel("channel1");
  const [IDs, setIDs] = useState([]);

  window.addEventListener("online", function (e) {
    let length = this.localStorage.getItem("idb-length");
    if (length === null) length = 0;
    length = parseInt(length);

    store.dispatch(updateIndexedDB({ length: length }));
    if (length > 0) getIDB();

    channel1.onmessage = (e) => {
      if (IDs.includes(e.data.ID)) return;
      let tempIDs = IDs;
      tempIDs.push(e.data.ID);
      setIDs(tempIDs);
      resendPostRequests(e.data, instance);
    };
  });

  // Check if caches is available
  if ('caches' in window) {
    // fill API Cache
    caches
      .open("api-cache")
      .then((cache) => cache.keys())
      .then((keys) => {
        const priorityCache = ["farms", "fieldsfromid", "crops", "fertilisers"];
        let numMatches = keys.reduce((total, current) => {
          if (
            total !== priorityCache.length &&
            priorityCache.some((route) => current.url.includes(route))
          )
            return total + 1;
          return total;
        }, 0);
        if (numMatches < priorityCache.length) return true;
        return false;
      })
      .then((needFillCache) => {
        if (!needFillCache) return;

        const priorityCache = ["farms", "fieldsfromid", "crops", "fertilisers"]; // Ensure this is available within scope

        // Fetch priority cache items and add to cache
        priorityCache.forEach(async (item) => {
          try {
            const response = await authenticateFetch(instance, `/api/${item}`, {
              headers: new Headers({
                "Content-Type": "application/json",
              }),
            });
            const data = await response.json();
            console.log(`Fetched and cached ${item}:`, data);
          } catch (error) {
            console.error(`Error fetching and caching ${item}:`, error);
          }
        });
      })
      .catch((error) => {
        console.error("Error in cache handling:", error);
      });
  } else {
    console.warn('Caches API not supported in this browser');
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await authenticateFetch(instance, "/api/v1/database/accountsettings", {
          headers: new Headers({
            "Content-Type": "application/json",
          }),
        });
        const result = await response.json();
        console.log("Fetched data:", result);
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [instance]);

  const Main = lazyWithRetry(() => import("./pages/Main"));
  const Business = lazyWithRetry(() => import("./pages/Business"));
  const Consultant = lazyWithRetry(() => import("./pages/Consultant"));
  const Data = lazyWithRetry(() => import("./pages/Data"));
  const Dashboard = lazyWithRetry(() => import("./pages/Dashboard"));
  const Feedback = lazyWithRetry(() => import("./pages/Feedback"));
  const Mixes = lazyWithRetry(() => import("./pages/Mixes"));
  const Notifications = lazyWithRetry(() => import("./pages/Notifications"));
  const Records = lazyWithRetry(() => import("./pages/Records"));
  const Recommendation = lazyWithRetry(() => import("./pages/Recommendation"));
  const Settings = lazyWithRetry(() => import("./pages/Settings"));
  const Suppliers = lazyWithRetry(() => import("./pages/Suppliers"));

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Page />,
      errorElement: <SiteError />,
      children: [
        {
          path: "/",
          element: (
            <Provider store={store}>
              <Main />
            </Provider>
          ),
        },
        {
          path: "/business",
          element: <Business />,
        },
        {
          path: "/consultant",
          element: <Consultant />,
        },
        {
          path: "/data",
          element: <Data />,
        },
        {
          path: "/dashboard",
          element: <Dashboard />,
        },
        {
          path: "/feedback",
          element: <Feedback />,
          loader: (params) => {
            const sp = new URL(params.request.url).searchParams;
            return Object.fromEntries(sp);
          },
        },
        {
          path: "/mixes",
          element: <Mixes />,
        },
        {
          path: "/notifications/:type",
          element: <Notifications />,
          loader: (params) => {
            const sp = new URL(params.request.url).searchParams;
            return Object.fromEntries(sp);
          },
        },
        {
          path: "/notifications",
          element: <Notifications />,
        },
        {
          path: "/records",
          element: <Records />,
        },
        {
          path: "/recommendation",
          element: <Recommendation />,
        },
        {
          path: "/settings",
          element: <Settings />,
        },
        {
          path: "/suppliers",
          element: <Suppliers />,
        },
      ],
    },
  ]);

  return (
    <div>
      <RouterProvider router={router}>
        <AnimatePresence />
      </RouterProvider>
    </div>
  );
}

export default App;
