import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";

import { usePWAInstall } from "react-use-pwa-install";
import useAccessControl from "../contexts/AccessControlContext";
import { getAll } from "../utils/notificationDB";
import SignOutButton from "./Buttons/SignOutButton";
import {
  BusinessIcon,
  DownloadIcon,
  FeedbackIcon,
  CalendarIcon1,
  NotificationIcon,
  RecordsIcon,
  SettingsIcon,
  SupplierIcon,
  SupportIcon,
} from "./Icons";
import PingingCircle from "./PingingCircle";
import { buttonConfig } from "./Styles";

export default function SideMenu(props) {
  const { accessControl } = useAccessControl();
  const [notifications, setNotifications] = React.useState(0);

  getAll().then((n) => setNotifications(n.length));

  function closeMenu() {
    props.isOpenMethod(false);
  }

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          props.menuRef.current &&
          !props.menuRef.current.contains(event.target)
        ) {
          props.isOpenMethod(false);
        }
      }
      document.addEventListener("click", handleClickOutside);
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }, [ref]);
  }

  const sideMenuRef = useRef(null);
  useOutsideAlerter(sideMenuRef);

  const activeStyle = {
    color: "#f59e0b",
  };
  const install = usePWAInstall();
  return (
    <section
      ref={sideMenuRef}
      id="side-menu"
      className={
        " sm:hidden flex flex-col justify-between  pb-0 w-60 h-[calc(100vh_-_66px)] z-20 p-5  text-white " +
        buttonConfig.darkBlue.bgColor
      }
    >
      <div className="flex flex-col space-y-5 text-xl">
        <div className="flex justify-between items-center my-2">
          <NavLink
            onClick={() => props.isOpenMethod(false)}
            to="/settings"
            className="flex justify-between items-center  hover:text-amber-500"
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            <SettingsIcon size={24} />
          </NavLink>

          <NavLink
            onClick={() => props.isOpenMethod(false)}
            to="/notifications"
            className=" flex justify-between items-center hover:text-amber-500"
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            <div className="relative">
              <NotificationIcon size={24} />
              {notifications > 0 && (
                <PingingCircle size={"small"} content={notifications} />
              )}
            </div>
          </NavLink>
          <SignOutButton />

          {/* <button className="text-right text-4xl" onClick={() => closeMenu()}>
            &times;
          </button> */}
        </div>

        <ul className="flex flex-col space-y-5 justify-start">
          <hr style={{ opacity: 0.5 }} />

          {accessControl?.AccessRecords && (
            <>
              <NavLink
                onClick={() => props.isOpenMethod(false)}
                to="/records"
                className=" flex justify-between items-center hover:text-amber-500"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                <RecordsIcon size={24} />
                Records
              </NavLink>
              <hr style={{ opacity: 0.5 }} />
            </>
          )}

          <NavLink
            onClick={() => props.isOpenMethod(false)}
            to="/mixes"
            className=" flex justify-between items-center hover:text-amber-500"
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            <CalendarIcon1 size={24} />
            Calendar
          </NavLink>
          <hr style={{ opacity: 0.5 }} />

          {accessControl?.AccessSuppliersList && (
            <>
              <NavLink
                onClick={() => props.isOpenMethod(false)}
                to="/suppliers"
                className="flex justify-between items-center  hover:text-amber-500"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                <SupplierIcon />
                Providers
              </NavLink>
              <hr style={{ opacity: 0.5 }} />
            </>
          )}

{accessControl?.AccessConsultant && (
            <>
              <NavLink
                onClick={() => props.isOpenMethod(false)}
                to="/consultant"
                className=" flex justify-between items-center hover:text-amber-500"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                <SupportIcon size={24} />
                Carers
              </NavLink>
              <hr style={{ opacity: 0.5 }} />
            </>
          )}

          <NavLink
            onClick={() => props.isOpenMethod(false)}
            to="/feedback"
            className=" flex justify-between items-center hover:text-amber-500"
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            <FeedbackIcon color="white" size={24} />
            Feedback
          </NavLink>
          <hr style={{ opacity: 0.5 }} />

          <NavLink
            onClick={() => props.isOpenMethod(false)}
            to="/business"
            className=" flex justify-between items-center hover:text-amber-500"
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            <BusinessIcon color="white" size={24} />
            Business
          </NavLink>
          <hr style={{ opacity: 0.5 }} />



          {install && (
            <>
              <button
                onClick={() => install()}
                className="flex justify-between items-center  hover:text-amber-500"
              >
                <DownloadIcon />
                Install App
              </button>
              <hr style={{ opacity: 0.5 }} />
            </>
          )}
        </ul>
      </div>
      <div className="  border-l  bg-white w-60 -mx-5 p-4">
        <NavLink className="" onClick={() => props.isOpenMethod(false)} to="/">
          <img
            src="/img/Intermentis-1600px.jpg"
            alt="Intermentis - Redefining Intelligence"
          />
        </NavLink>
      </div>
    </section>
  );
}
