
import { withMsal } from "@azure/msal-react";
import DOMPurify from "dompurify";
import { Button, Card, Dimmer } from "semantic-ui-react";

import LoadingComponent from "../../models/LoadingComponent";
import authenticateFetch from "../../utils/authenticateFetch";

class EULAPopup extends LoadingComponent {
  constructor(props) {
    super(props);
    this.state = {
      business: undefined,
      open: true,
    };
    this.popupChannel = new BroadcastChannel("popupChannel");
    this.signCallback = props.callback;
    this.instance = props.msalContext.instance;
  }

  agree = () => {
    authenticateFetch(this.instance, "/api/v1/settings/signeula", {
      method: "POST",
      headers: new Headers(),
    })
      .then(this.signCallback)
      .then(this.handleClose);
  };

  handleClose = () => {
    this.setState({ ...this.state, open: false });
  };

  componentDidMount() {
    authenticateFetch(this.instance, "/api/v1/database/usersbusiness", {
      method: "GET",
      headers: new Headers(),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ ...this.state, business: json, initialised: true });
        this.popupChannel.postMessage("pb-24");
      });
  }

  loadedRender() {
    return (
      <Dimmer active={this.state.open}>
        <Card className="!w-[90vw] !h-[90vh] text-black">
          <Card.Content>
            <div
              className="p-12 pt-10 rounded-md bg-white overflow-y-scroll !h-[78vh]"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(this.state.business.EULAText),
              }}
            ></div>
          </Card.Content>
          <Card.Content extra>
            <div className="ui two buttons">
              <Button onClick={this.agree} className="intermentis-blue">
                Agree
              </Button>
              <Button
                onClick={() => {
                  this.instance.logoutRedirect({
                    postLogoutRedirectUri: "/",
                    mainWindowRedirectUri: "/",
                  });
                }}
                color="red"
              >
                Leave
              </Button>
            </div>
          </Card.Content>
        </Card>
      </Dimmer>
    );
  }
}

export default withMsal(EULAPopup);

