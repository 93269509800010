import { updateIndexedDB } from "../redux/actions";
import store from "../redux/store";

function open(version) {
  const indexedDB =
    window.indexedDB ||
    window.mozIndexedDB ||
    window.webkitIndexedDB ||
    window.msIndexedDB ||
    window.shimIndexedDB;

  const request = indexedDB.open("Network", version);
  request.onerror = function (event) {
    console.error("An error occurred with IndexedDB", event);
  };
  // Create the schema on create and version upgrade

  if (version === 1) {
    request.onupgradeneeded = function () {
      const db = request.result;
      const store = db.createObjectStore("post-requests", {
        keyPath: "ID",
      });
      store.createIndex("recID", ["recID"], { unique: false });
    };
  }

  return request;
}

export function addIDB(obj) {
  const request = open(1);
  request.onsuccess = function () {
    const db = request.result;
    const transaction = db.transaction("post-requests", "readwrite");
    const store = transaction.objectStore("post-requests");
    store.put(obj);

    updateLength(1);

    transaction.oncomplete = function () {
      db.close();
    };
  };
}

function updateLength(increment) {
  let length = store.getState().indexedDB.length;
  store.dispatch(updateIndexedDB({ length: length + increment }));
  localStorage.setItem("idb-length", store.getState().indexedDB.length);
}

export function getIDB() {
  const request = open(1);
  const channel1 = new BroadcastChannel("channel1");

  request.onsuccess = function () {
    const db = request.result;
    const transaction = db.transaction("post-requests", "readwrite");
    const store = transaction.objectStore("post-requests");

    const cursor = store.openCursor();
    cursor.onsuccess = function (e) {
      const result = e.target.result;
      if (result === null) return;
      channel1.postMessage(result.value);

      const deleteQuery = store.delete(result.key);
      deleteQuery.onsuccess = function () {
        updateLength(-1);
      };
      result.continue();
    };

    transaction.oncomplete = function () {
      db.close();
    };
  };
}
