import { useMsal } from "@azure/msal-react";

import { SignoutIcon } from "../Icons";

export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
      mainWindowRedirectUri: "/",
    });
  };

  return (
    <>
      <button
        onClick={() => handleLogout()}
        className="flex  justify-between items-center hover:text-amber-500 sm:hover:text-current"
      >
        <i className="sm:hidden">
          <SignoutIcon color="white" />
        </i>

        <p className="hidden sm:inline">Signout</p>
      </button>
    </>
  );
};

export default SignOutButton;
