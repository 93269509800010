import {
  FORM_CHANGE,
  FORM_RESET,
  INDEXED_CHANGE,
  INDEXED_RESET,
} from "./actionTypes";

export const updateForm = (content) => ({
  type: FORM_CHANGE,
  payload: {
    content,
  },
});

export const resetForm = (content) => ({
  type: FORM_RESET,
  payload: {
    content,
  },
});

export const updateIndexedDB = (content) => ({
  type: INDEXED_CHANGE,
  payload: {
    content,
  },
});

export const resetIndexedDB = (content) => ({
  type: INDEXED_RESET,
  payload: {
    content,
  },
});
