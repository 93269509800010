import { FORM_CHANGE, FORM_RESET } from "../actionTypes";

const parseIntNoThrow = (value) => {
  if (!isNaN(value)) return parseInt(value);
  return undefined;
};

const initialState = {
  placeID: parseIntNoThrow(getFromLocalStorage("recentPlaceID")), // Int
  deviceID: parseIntNoThrow(getFromLocalStorage("recentDeviceID")), // Int
  placeName: getFromLocalStorage("recentPlaceName"), // String
  deviceName: getFromLocalStorage("recentDeviceName"), // String
  newCrop: {
    cropID: undefined, // Int
    amountByArea: undefined, // Float
    area: undefined, // Float
    plantedDate: undefined, // Date
    estimatedYield: undefined, // Float
    budget: undefined, // Float (Money)
    notes: undefined, // String
  },
  newFertiliser: {
    fertiliser: undefined, // Int
    dateApplied: undefined, // Date
    amount: undefined, // Float
  },
  cropHistoryID: undefined, // Int
  yield: {
    harvestDate: undefined, // Date
    scenarioID: undefined, // Int
    grossWeight: undefined, // Float
    dressWeight: undefined, // Float
    testWeight: undefined, // Float
  },
};

function getFromLocalStorage(txt) {
  let value = localStorage.getItem(txt) ?? undefined;
  if (value === undefined) return value;
  if (typeof parseInt(value) === NaN) return parseInt(value);
  return value;
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FORM_CHANGE: {
      return {
        ...state,
        ...action.payload.content,
      };
    }
    case FORM_RESET:
      return initialState;
    default:
      return state;
  }
}
