import { Alert } from "@mui/material";
import { useEffect, useState } from "react";
import { TransitionGroup } from "semantic-ui-react";

export default function ResentPopup() {
  const resentStatus = new BroadcastChannel("resentStatus");
  const [numMessages, setMessages] = useState(0);
  const [failed, setFailed] = useState(false);

  resentStatus.onmessage = (e) => {
    if (e) setMessages(numMessages + 1);
    else setFailed(true);
  };

  useEffect(() => {
    if (numMessages > 0)
      setTimeout(() => {
        setMessages(0);
      }, 2400);
  }, [numMessages]);

  return (
    <TransitionGroup animation="fade right" duration={1000}>
      {numMessages > 0 && (
        <div className="flex items-center">
          {failed && (
            <Alert severity="error" className="flex items-center ">
              Failed to submit saved entries
            </Alert>
          )}

          {!failed && (
            <Alert className="flex items-center ">
              Successfully submitted saved entries
            </Alert>
          )}
        </div>
      )}
    </TransitionGroup>
  );
}
