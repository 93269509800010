/*
import { loginRequest } from "../authConfig";

export default async function (instance) {
  const request = {
    ...loginRequest,
    redirectUri: window.location.origin,
    account: instance.getActiveAccount(),
  };

  // Silently acquires an access token which is then attached to a request for Microsoft Graph data
  try {
    let response = await instance.acquireTokenSilent(request);
    return response.accessToken;
  } catch (e) {
    let response = await instance.acquireTokenRedirect(request);
    return response.accessToken;
  }
}
  GP TODO GP
  */

import { loginRequest } from "../authConfig";

export default async function requestAccessToken(instance) {
  const request = {
    ...loginRequest,
    redirectUri: window.location.origin,
    account: instance.getActiveAccount(),
  };

  try {
    let response = await instance.acquireTokenSilent(request);
    console.log("Access token acquired silently:", response.accessToken);
    return response.accessToken;
  } catch (e) {
    console.log("Silent token acquisition failed, attempting redirect:", e);
    let response = await instance.acquireTokenRedirect(request);
    console.log("Access token acquired via redirect:", response.accessToken);
    return response.accessToken;
  }
}

