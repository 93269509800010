export default function (url) {
  const cssLink = document.getElementById("BusinessCSS");
  if (cssLink) {
    cssLink.href = url;
    return true;
  } else {
    cssLink.rel = "stylesheet";
    return document.head.appendChild(cssLink);
  }
}
