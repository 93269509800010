import { createContext, useContext } from "react";

export const AccessControlContext = createContext();

export default function useAccessControl() {
  const context = useContext(AccessControlContext);

  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }

  return context;
}
