import "../App.css";

import { useMsal } from "@azure/msal-react";
import { Menu, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import useAccessControl from "../contexts/AccessControlContext";
import authenticateFetch from "../utils/authenticateFetch";
import createBusinessStyle from "../utils/createBusinessStyle";
import { getAll } from "../utils/notificationDB";
import stringToTitleCase from "../utils/stringToTitleCase";
import SignOutButton from "./Buttons/SignOutButton";
import {
  AccountIcon,
  BusinessIcon,
  NotificationIcon,
  SettingsIcon,
} from "./Icons";
import PingingCircle from "./PingingCircle";
import ResentPopup from "./Popups/ResentPopup";

const activeStyle = {
  color: "#333333",
};

export default function () {
  const { instance } = useMsal();
  const { accessControl } = useAccessControl();

  const [business, setBusiness] = useState(false);
  const [notifications, setNotifications] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getAll().then((n) => setNotifications(n.length));
  });

  useEffect(() => {
    authenticateFetch(instance, "/api/v1/database/usersbusiness", {
      method: "GET",
      headers: new Headers(),
    })
      .then((res) => res.json())
      .then(
        (business) =>
          setBusiness(business) || createBusinessStyle(business.BusinessCSSUrl)
      );
  }, [instance]);

  return (
    <header
      className={
        "md:mt-4 mb-6 p-5 relative bg-white shadow-lg rounded-sm hidden sm:block"
      }
    >
      <div className="flex items-center justify-between gap-3">
        <div>
          <a
            onClick={handleClick}
            className="hover:cursor-pointer flex items-center gap-2"
          >
            <AccountIcon />
            <p>{stringToTitleCase(instance.getActiveAccount().name)}</p>
          </a>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/feedback"
            >
              <MenuItem onClick={handleClose}>Feedback</MenuItem>
            </NavLink>
            {accessControl?.AccessSuppliersList && (
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/suppliers"
              >
                <MenuItem onClick={handleClose}>Suppliers</MenuItem>
              </NavLink>
            )}

            {accessControl?.AccessConsultant && (
              <NavLink
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                to="/consultant"
              >
                <MenuItem onClick={handleClose}>Consultant</MenuItem>
              </NavLink>
            )}

            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/data"
            >
              <MenuItem onClick={handleClose}>Data</MenuItem>
            </NavLink>
            <MenuItem>
              <SignOutButton />
            </MenuItem>
          </Menu>
        </div>
        <div className="flex gap-2 items-center">
          <NavLink className="" to="/business">
            <BusinessIcon />
          </NavLink>
          <NavLink className="" to="/notifications">
            <div className="relative">
              <NotificationIcon size={24} />
              {notifications > 0 && (
                <PingingCircle size={"small"} content={notifications} />
              )}
            </div>
          </NavLink>
          <NavLink className="" to="/settings">
            <SettingsIcon size={24} />
          </NavLink>
        </div>
      </div>

      <div className=" flex justify-center ">
        <div className=" flex flex-col  justify-end items-center ">
          {/*
          {business && (
            <a target="_blank" href={business.BusinessURL}>
              <img
                src={business.BusinessLogoURL}
                id="BusinessLogo"
                alt={`${business.BusinessName} logo`}
                 className=" max-w-[9rem]"
              />
            </a>
          )}
            */}
          <NavLink className="" to="/">
            <img
              src="/img/Intermentis-1600px.png"
              alt="Intermentis - Redefining Intelligence"
              className=" max-w-[18rem]"
            />
          </NavLink>
        </div>
      </div>

      <div className="flex items-center justify-between">
        <ResentPopup />
      </div>

      <nav className="flex xl:gap-6 lg:gap-4 gap-2 justify-center md:gap-1   items-center flex-wrap mt-10 text-center text-xl">
        <NavLink
          className=""
          to="/dashboard"
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          Dashboard
        </NavLink>
        |
        <NavLink
          className=""
          to="/"
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          Places
        </NavLink>
        |
        {accessControl?.AccessRecords && (
          <>
            <NavLink
              className=" whitespace-nowrap "
              to="/records"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              Records
            </NavLink>
            |
          </>
        )}
        <NavLink
          className=" whitespace-nowrap  "
          to="/recommendation"
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          Tools
        </NavLink>
        |
        <NavLink
          className=" whitespace-nowrap "
          to="/mixes"
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          Scenarios
        </NavLink>
      </nav>
    </header>
  );
}
