import { buttonConfig } from "./Styles";

let size = "28";

function changeSize(sz) {
  let size;
  if (typeof sz === typeof 1) return sz;
  switch (sz) {
    case "small":
      size = "20";
      break;
    default:
      size = "28";
      break;
  }
  return size;
}

function getFill(clr) {
  let color = buttonConfig[clr] ?? " ";
  return color.fill;
}

export function FarmIcon(props) {
  let color = getFill(props.color);
  let margin = props.margin ?? "";

  return (
    <svg
      width={size}
      height={size}
      className={color + " fill-current " + margin}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="#000000"
    >
      <rect fill="none" height="24" width="24" />
      <path d="M12,3L6,7.58V6H4v3.11L1,11.4l1.21,1.59L4,11.62V21h7v-6h2v6h7v-9.38l1.79,1.36L23,11.4L12,3z M10,1c0,1.66-1.34,3-3,3 C6.45,4,6,4.45,6,5H4c0-1.66,1.34-3,3-3c0.55,0,1-0.45,1-1H10z" />
    </svg>
  );
}

export function BuildingIcon(props) {
  let color = getFill(props.color);
  let margin = props.margin ?? "";
  let size = props.size ?? "24px"; // Added size variable

  return (
    <svg
      width={size}
      height={size}
      className={color + " fill-current " + margin}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="#000000"
    >
      <rect fill="none" height="24" width="24" />
      <path 
        d="M12 2L2 7v15h20V7L12 2zm6 18h-4v-2h4v2zm0-4h-4v-4h4v4zm-6 4H8v-2h4v2zm0-4H8v-4h4v4zm6-6h-4V8h4v2zm-6 0H8V8h4v2z"
      />      
    </svg>
  );
}

export function CropIcon(props) {
  return (
    <svg
      className="fill-current"
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      viewBox="0 0 24 24"
      fill="#000000"
    >
      <rect fill="none" height="24" width="24" />
      <path d="M12,20H2v-2h5.75l0,0C7.02,15.19,4.81,12.99,2,12.26C2.64,12.1,3.31,12,4,12C8.42,12,12,15.58,12,20z M22,12.26 C21.36,12.1,20.69,12,20,12c-2.93,0-5.48,1.58-6.88,3.93c0.29,0.66,0.53,1.35,0.67,2.07c0.13,0.65,0.2,1.32,0.2,2h2h6v-2h-5.75 C16.98,15.19,19.19,12.99,22,12.26z M15.64,11.02c0.78-2.09,2.23-3.84,4.09-5C15.44,6.16,12,9.67,12,14c0,0.01,0,0.02,0,0.02 C12.95,12.75,14.2,11.72,15.64,11.02z M11.42,8.85C10.58,6.66,8.88,4.89,6.7,4C8.14,5.86,9,8.18,9,10.71c0,0.21-0.03,0.41-0.04,0.61 c0.43,0.24,0.83,0.52,1.22,0.82C10.39,10.96,10.83,9.85,11.42,8.85z" />
    </svg>
  );
}

export function CropHistoryIcon(props) {
  return (
    <svg
      className="fill-current"
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      viewBox="0 0 48 48"
    >
      <path d="M23.85 42q-7.45 0-12.65-5.275T6 23.95h3q0 6.25 4.3 10.65T23.85 39q6.35 0 10.75-4.45t4.4-10.8q0-6.2-4.45-10.475Q30.1 9 23.85 9q-3.4 0-6.375 1.55t-5.175 4.1h5.25v3H7.1V7.25h3v5.3q2.6-3.05 6.175-4.8Q19.85 6 23.85 6q3.75 0 7.05 1.4t5.775 3.825q2.475 2.425 3.9 5.675Q42 20.15 42 23.9t-1.425 7.05q-1.425 3.3-3.9 5.75-2.475 2.45-5.775 3.875Q27.6 42 23.85 42Zm6.4-9.85-7.7-7.6v-10.7h3v9.45L32.4 30Z" />
    </svg>
  );
}

export function FertiliserIcon(props) {
  return (
    <svg
      className="fill-current"
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      viewBox="0 0 48 48"
    >
      <path d="M7.25 18.05q-.55 0-.95-.45-.4-.45-.4-1.05 0-.6.4-1.05.4-.45.95-.45h7.45q1.45 0 2.25.9t1.05 2.1ZM11.4 40q-3.95 0-6.675-2.775Q2 34.45 2 30.5q0-3.95 2.775-6.725Q7.55 21 11.5 21q1.6 0 3.2.55t2.9 1.65h3.45q2.1 0 3.525-1.475Q26 20.25 26 18.15v-5.2l-2.45-2.4-3.05 3.05-.95-.9 7.1-7.1.9.9-3.1 3.1 2.45 2.45h13.6q1.3 0 2.15.85.85.85.85 2.15V28q1.15 1 1.825 2.35t.675 2.9q0 2.8-1.975 4.775Q42.05 40 39.25 40q-2.8 0-4.775-1.975Q32.5 36.05 32.5 33.4q0-.3.025-.725T32.6 32H20.9q-.55 3.45-3.2 5.725Q15.05 40 11.4 40Zm.1-3q2.7 0 4.6-1.9 1.9-1.9 1.9-4.6 0-2.7-1.9-4.6-1.9-1.9-4.6-1.9-2.7 0-4.6 1.9Q5 27.8 5 30.5q0 2.7 1.9 4.6Q8.8 37 11.5 37Zm27.75 0q1.55 0 2.65-1.1 1.1-1.1 1.1-2.65 0-1.55-1.1-2.65-1.1-1.1-2.65-1.1-1.55 0-2.65 1.1-1.1 1.1-1.1 2.65 0 1.55 1.1 2.65 1.1 1.1 2.65 1.1ZM11.5 34q-1.45 0-2.475-1.025Q8 31.95 8 30.5q0-1.45 1.025-2.475Q10.05 27 11.5 27q1.45 0 2.475 1.025Q15 29.05 15 30.5q0 1.45-1.025 2.475Q12.95 34 11.5 34Z" />
    </svg>
  );
}

export function FertiliserHistoryIcon(props) {
  return (
    <svg
      className="fill-current"
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      viewBox="0 0 48 48"
    >
      <path d="M23.85 42q-7.45 0-12.65-5.275T6 23.95h3q0 6.25 4.3 10.65T23.85 39q6.35 0 10.75-4.45t4.4-10.8q0-6.2-4.45-10.475Q30.1 9 23.85 9q-3.4 0-6.375 1.55t-5.175 4.1h5.25v3H7.1V7.25h3v5.3q2.6-3.05 6.175-4.8Q19.85 6 23.85 6q3.75 0 7.05 1.4t5.775 3.825q2.475 2.425 3.9 5.675Q42 20.15 42 23.9t-1.425 7.05q-1.425 3.3-3.9 5.75-2.475 2.45-5.775 3.875Q27.6 42 23.85 42Zm6.4-9.85-7.7-7.6v-10.7h3v9.45L32.4 30Z" />
    </svg>
  );
}

export function FieldIcon(props) {
  return (
    <svg
      className="fill-current"
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      viewBox="0 0 48 48"
    >
      <path d="m24 41.5-18-14 2.5-1.85L24 37.7l15.5-12.05L42 27.5Zm0-7.6-18-14 18-14 18 14Z" />
    </svg>
  );
}

export function YieldsIcon(props) {
  let size = changeSize(props.size);

  return (
    <svg
      className="fill-current"
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      viewBox="0 0 48 48"
    >
      <path d="M24 44q0-3.5 1.5-6.75t4-5.75q2.5-2.5 5.75-4T42 26q0 3.5-1.5 6.75t-4 5.75q-2.5 2.5-5.75 4T24 44Zm0 0q0-3.5-1.5-6.75t-4-5.75q-2.5-2.5-5.75-4T6 26q0 3.5 1.5 6.75t4 5.75q2.5 2.5 5.75 4T24 44Zm0-24.5q1.7 0 2.85-1.2Q28 17.1 28 15.5q0-1.7-1.15-2.85Q25.7 11.5 24 11.5q-1.6 0-2.8 1.15Q20 13.8 20 15.5q0 1.6 1.2 2.8 1.2 1.2 2.8 1.2Zm0 9.5q-1.8 0-3.275-1.075Q19.25 26.85 18.55 25.1q-.3 0-.625.025t-.625.025q-2.4 0-4.1-1.7-1.7-1.7-1.7-4.1 0-1 .4-1.975.4-.975 1.15-1.875-.7-.9-1.1-1.875-.4-.975-.4-1.975 0-2.4 1.675-4.1 1.675-1.7 4.075-1.7.3 0 .625.025t.625.025q.7-1.75 2.175-2.825Q22.2 2 24 2q1.8 0 3.275 1.075Q28.75 4.15 29.45 5.9q.3 0 .625-.025t.625-.025q2.4 0 4.075 1.7 1.675 1.7 1.675 4.1 0 1-.375 1.975-.375.975-1.125 1.875.7.9 1.1 1.875.4.975.4 1.975 0 2.4-1.675 4.1-1.675 1.7-4.075 1.7-.3 0-.625-.025t-.625-.025q-.7 1.75-2.175 2.825Q25.8 29 24 29Z" />
    </svg>
  );
}

export function SoilReportsIcon(props) {
  return (
    <svg
      className="fill-current"
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      viewBox="0 0 48 48"
    >
      <path d="M16 23.5h16v-3H16Zm0-8h16v-3H16Zm23.9 26.3-8.5-11.1q-.8-1-1.95-1.6-1.15-.6-2.45-.6H8V7q0-1.2.9-2.1Q9.8 4 11 4h26q1.2 0 2.1.9.9.9.9 2.1v34q0 .2-.025.4t-.075.4ZM11 44q-1.2 0-2.1-.9Q8 42.2 8 41v-9.5h19q.6 0 1.125.275t.925.775l8.7 11.35q-.2.05-.375.075Q37.2 44 37 44Z" />
    </svg>
  );
}

export function ReportsIcon(props) {
  return (
    <svg
      className="fill-current"
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      viewBox="0 0 48 48"
    >
      <path d="M16 23.5h16v-3H16Zm0-8h16v-3H16Zm23.9 26.3-8.5-11.1q-.8-1-1.95-1.6-1.15-.6-2.45-.6H8V7q0-1.2.9-2.1Q9.8 4 11 4h26q1.2 0 2.1.9.9.9.9 2.1v34q0 .2-.025.4t-.075.4ZM11 44q-1.2 0-2.1-.9Q8 42.2 8 41v-9.5h19q.6 0 1.125.275t.925.775l8.7 11.35q-.2.05-.375.075Q37.2 44 37 44Z" />
    </svg>
  );
}

export function DateIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="26"
      fill="#000000"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z" />
    </svg>
  );
}

export function BarGraphIcon(props) {
  return (
    <div className="w-7 h-7">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        // width={size}
        // height={size}
        viewBox="0 0 22 22"
        fill="#000000"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z" />
      </svg>
    </div>
  );
}
export function LocationIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
    </svg>
  );
}
export function NotesIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M21 11.01L3 11v2h18zM3 16h12v2H3zM21 6H3v2.01L21 8z" />
    </svg>
  );
}
export function BackIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z" />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M0 0h24v24H0V0z"
        fill="none"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"
      />
    </svg>
  );
}
export function NextIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z" />
    </svg>
  );
}
export function AreaIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M9.17 15.5h5.64l1.14 3h2.09l-5.11-13h-1.86l-5.11 13h2.09l1.12-3zM12 7.98l2.07 5.52H9.93L12 7.98zM20 2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 18H4V4h16v16z" />
    </svg>
  );
}
export function CloseIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
    </svg>
  );
}
export function NotificationIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);

  if (props.active)
    return (
      <svg
        className={
          // getFill("amber") +
          " fill-current"
        }
        xmlns="http://www.w3.org/2000/svg"
        height={size}
        width={size}
        viewBox="0 0 24 24"
        fill="#000000"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M7.58 4.08L6.15 2.65C3.75 4.48 2.17 7.3 2.03 10.5h2c.15-2.65 1.51-4.97 3.55-6.42zm12.39 6.42h2c-.15-3.2-1.73-6.02-4.12-7.85l-1.42 1.43c2.02 1.45 3.39 3.77 3.54 6.42zM18 11c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2v-5zm-6 11c.14 0 .27-.01.4-.04.65-.14 1.18-.58 1.44-1.18.1-.24.15-.5.15-.78h-4c.01 1.1.9 2 2.01 2z" />
      </svg>
    );

  return (
    <svg
      className={color + " fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      viewBox="0 0 24 24"
      fill="#000000"
    >
      <path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z" />
    </svg>
  );
}

export function AccountIcon(props) {
  let color = getFill(props.color);
  return (
    <svg
      className={color + " fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 24 24"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
    >
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 4c1.93 0 3.5 1.57 3.5 3.5S13.93 13 12 13s-3.5-1.57-3.5-3.5S10.07 6 12 6zm0 14c-2.03 0-4.43-.82-6.14-2.88C7.55 15.8 9.68 15 12 15s4.45.8 6.14 2.12C16.43 19.18 14.03 20 12 20z" />
      </g>
    </svg>
  );
}

export function SettingsIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);

  return (
    <svg
      className={" fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 24 24"
      height={size}
      width={size}
      viewBox="0 0 24 24"
      fill="#000000"
    >
      <g>
        <path d="M0,0h24v24H0V0z" fill="none" />
        <path d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z" />
      </g>
    </svg>
  );
}

export function RecordsIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);
  return (
    <svg
      className={" fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 24 24"
      height={size}
      width={size}
      viewBox="0 0 24 24"
      fill="#000000"
    >
      <path d="M0,0h24v24H0V0z" fill="none" />
      <g>
        <path d="M19.5,3.5L18,2l-1.5,1.5L15,2l-1.5,1.5L12,2l-1.5,1.5L9,2L7.5,3.5L6,2v14H3v3c0,1.66,1.34,3,3,3h12c1.66,0,3-1.34,3-3V2 L19.5,3.5z M19,19c0,0.55-0.45,1-1,1s-1-0.45-1-1v-3H8V5h11V19z" />
        <rect height="2" width="6" x="9" y="7" />
        <rect height="2" width="2" x="16" y="7" />
        <rect height="2" width="6" x="9" y="10" />
        <rect height="2" width="2" x="16" y="10" />
      </g>
    </svg>
  );
}

export function DashboardIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);

  return (
    <svg
      className={color}
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 24 24"
      height={size}
      width={size}
      viewBox="0 0 24 24"
      fill="#000000"
    >
      <rect fill="none" height="24" width="24" />
      <path d="M5 9.2v6h3v-4h5v-4h3V3.2c0-.67-.54-1.2-1.2-1.2H6.2C5.54 2 5 2.54 5 3.2V9.2zM3 19v-2h18v2H3z"/>

    </svg>
  );
}
export function RecommendationIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={color}
      height={size}
      width={size}
      viewBox="0 0 24 24"
      fill="#000000"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M19 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h4l3 3 3-3h4c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-5.12 10.88L12 17l-1.88-4.12L6 11l4.12-1.88L12 5l1.88 4.12L18 11l-4.12 1.88z" />
    </svg>
  );
}

export function InteractionIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={color}
      height={size}
      width={size}
      viewBox="0 0 24 24"
      fill="#000000"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        d="M16 11c1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3 1.34 3 3 3zm-8 0c1.66 0 3-1.34 3-3S9.66 5 8 5 5 6.34 5 8s1.34 3 3 3zm8 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm-8 0c-2.67 0-8 1.34-8 4v2h4v-2c0-1.33 2.67-2 4-2s4 .67 4 2v2h4v-2c0-2.66-5.33-4-8-4z"
      />
      </svg>
  );
}

export function SupplierIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);
  return (
    <svg
      className="fill-current"
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zM6 18.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm13.5-9l1.96 2.5H17V9.5h2.5zm-1.5 9c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />
    </svg>
  );
}
export function DownloadIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);
  return (
    <svg
      className="fill-current"
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 24 24"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
    >
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <path d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M17,11l-1.41-1.41L13,12.17V4h-2v8.17L8.41,9.59L7,11l5,5 L17,11z" />
      </g>
    </svg>
  );
}
export function TickIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);
  return (
    <svg
      className="fill-current"
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      width="24px"
      viewBox="0 0 24 24"
      fill="#000000"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
    </svg>
  );
}
export function MenuIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);
  return (
    <svg
      className={color}
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      viewBox="0 0 24 24"
      fill="#000000"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
    </svg>
  );
}
export function SignoutIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);

  return (
    <svg
      className={" fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 24 24"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
    >
      <g>
        <path d="M0,0h24v24H0V0z" fill="none" />
      </g>
      <g>
        <path d="M17,8l-1.41,1.41L17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4L17,8z M5,5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h7v-2H5V5z" />
      </g>
    </svg>
  );
}
export function MixesIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);

  return (
    <svg
      className={" fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 24 24"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
    >
      <g>
        <path d="M0,0h24v24H0V0z" fill="none" />
      </g>
      <g>
        <g>
          <path d="M16.13,15.13L18,3h-4V2h-4v1H5C3.9,3,3,3.9,3,5v4c0,1.1,0.9,2,2,2h2.23l0.64,4.13C6.74,16.05,6,17.43,6,19v1 c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2v-1C18,17.43,17.26,16.05,16.13,15.13z M5,9V5h1.31l0.62,4H5z M12,19c-0.55,0-1-0.45-1-1 s0.45-1,1-1s1,0.45,1,1S12.55,19,12,19z M14.29,14H9.72L8.33,5h7.34L14.29,14z" />
        </g>
      </g>
    </svg>
  );
}

export function CalendarIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={color}
      height={size}
      width={size}
      viewBox="0 0 24 24"
      fill="#000000"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.1 0-1.99.9-1.99 2L3 20c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11zM7 11h5v5H7z" />
    </svg>
  );
}

export function CalendarIcon1(props) {
    let color = getFill(props.color);
    let size = changeSize(props.size);
  
    return (
      <svg
      className={" fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 24 24"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
      >
        <g>
          <path d="M0,0h24v24H0V0z" fill="none" />
        </g>
        <g>
          <path d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.1 0-1.99.9-1.99 2L3 20c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11zM7 11h5v5H7z" />
        </g>
      </svg>
    );
  }

export function DollarIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z" />
    </svg>
  );
}
export function PlusIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
    </svg>
  );
}
export function CameraIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M14.12 4l1.83 2H20v12H4V6h4.05l1.83-2h4.24M15 2H9L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2zm-3 7c1.65 0 3 1.35 3 3s-1.35 3-3 3-3-1.35-3-3 1.35-3 3-3m0-2c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5z" />
    </svg>
  );
}
export function RemixIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);

  return (
    <svg
      className="fill-current"
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M7 7h10v3l4-4-4-4v3H5v6h2V7zm10 10H7v-3l-4 4 4 4v-3h12v-6h-2v4z" />
    </svg>
  );
}
export function InputsIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);

  return (
    <svg
      className={color + " fill-current -mb-1"}
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 24 24"
      height="34px"
      width="34px"
      viewBox="0 0 24 24"
      fill="#000000"
    >
      <rect fill="none" height="28" width="28" />
      <path d="M3,10h11v2H3V10z M3,8h11V6H3V8z M3,16h7v-2H3V16z M18.01,12.87l0.71-0.71c0.39-0.39,1.02-0.39,1.41,0l0.71,0.71 c0.39,0.39,0.39,1.02,0,1.41l-0.71,0.71L18.01,12.87z M17.3,13.58l-5.3,5.3V21h2.12l5.3-5.3L17.3,13.58z" />
    </svg>
  );
}
export function InfoIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);

  return (
    <svg
      className={color + " fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
    </svg>
  );
}
export function ContactIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);

  return (
    <svg
      className={" fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M11.5 2C6.81 2 3 5.81 3 10.5S6.81 19 11.5 19h.5v3c4.86-2.34 8-7 8-11.5C20 5.81 16.19 2 11.5 2zm1 14.5h-2v-2h2v2zm0-3.5h-2c0-3.25 3-3 3-5 0-1.1-.9-2-2-2s-2 .9-2 2h-2c0-2.21 1.79-4 4-4s4 1.79 4 4c0 2.5-3 2.75-3 5z" />
    </svg>
  );
}
export function BusinessIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);

  return (
    <svg
      className={" fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M20 6h-4V4c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-6 0h-4V4h4v2z" />
    </svg>
  );
}
export function SupportIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);
  let fill = props.fill ?? " fill-current ";

  return (
    <svg
      className={fill}
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 24 24"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      fill="#000000"
    >
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <g>
          <path d="M21,12.22C21,6.73,16.74,3,12,3c-4.69,0-9,3.65-9,9.28C2.4,12.62,2,13.26,2,14v2c0,1.1,0.9,2,2,2h1v-6.1 c0-3.87,3.13-7,7-7s7,3.13,7,7V19h-8v2h8c1.1,0,2-0.9,2-2v-1.22c0.59-0.31,1-0.92,1-1.64v-2.3C22,13.14,21.59,12.53,21,12.22z" />
          <circle cx="9" cy="13" r="1" />
          <circle cx="15" cy="13" r="1" />
          <path d="M18,11.03C17.52,8.18,15.04,6,12.05,6c-3.03,0-6.29,2.51-6.03,6.45c2.47-1.01,4.33-3.21,4.86-5.89 C12.19,9.19,14.88,11,18,11.03z" />
        </g>
      </g>
    </svg>
  );
}
export function WebIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      fill="#000000"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2s.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2s.07-1.35.16-2h4.68c.09.65.16 1.32.16 2s-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2s-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z" />
    </svg>
  );
}
export function EmailIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      fill="#000000"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z" />
    </svg>
  );
}
export function PhoneIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      viewBox="0 0 24 24"
      fill="#000000"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M6.54 5c.06.89.21 1.76.45 2.59l-1.2 1.2c-.41-1.2-.67-2.47-.76-3.79h1.51m9.86 12.02c.85.24 1.72.39 2.6.45v1.49c-1.32-.09-2.59-.35-3.8-.75l1.2-1.19M7.5 3H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.49c0-.55-.45-1-1-1-1.24 0-2.45-.2-3.57-.57-.1-.04-.21-.05-.31-.05-.26 0-.51.1-.71.29l-2.2 2.2c-2.83-1.45-5.15-3.76-6.59-6.59l2.2-2.2c.28-.28.36-.67.25-1.02C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1z" />
    </svg>
  );
}
export function FeedbackIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);

  return (
    <svg
      className={" fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M15 4v7H5.17L4 12.17V4h11m1-2H3c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1zm5 4h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1z" />
    </svg>
  );
}
export function RefreshIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);

  return (
    <svg
      className={" fill-current"}
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z" />
    </svg>
  );
}
export function CloudIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM19 18H6c-2.21 0-4-1.79-4-4s1.79-4 4-4h.71C7.37 7.69 9.48 6 12 6c3.04 0 5.5 2.46 5.5 5.5v.5H19c1.66 0 3 1.34 3 3s-1.34 3-3 3z" />
    </svg>
  );
}
export function WindIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 24 24"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
    >
      <g>
        <path d="M0,0h24v24H0V0z" fill="none" />
      </g>
      <g>
        <g>
          <path d="M14.5,17c0,1.65-1.35,3-3,3s-3-1.35-3-3h2c0,0.55,0.45,1,1,1s1-0.45,1-1s-0.45-1-1-1H2v-2h9.5 C13.15,14,14.5,15.35,14.5,17z M19,6.5C19,4.57,17.43,3,15.5,3S12,4.57,12,6.5h2C14,5.67,14.67,5,15.5,5S17,5.67,17,6.5 S16.33,8,15.5,8H2v2h13.5C17.43,10,19,8.43,19,6.5z M18.5,11H2v2h16.5c0.83,0,1.5,0.67,1.5,1.5S19.33,16,18.5,16v2 c1.93,0,3.5-1.57,3.5-3.5S20.43,11,18.5,11z" />
        </g>
      </g>
    </svg>
  );
}

export function RainIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enable-background="new 0 0 24 24"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#000000"
    >
      <rect fill="none" height="24" width="24" />
      <path d="M12,2c-5.33,4.55-8,8.48-8,11.8c0,4.98,3.8,8.2,8,8.2s8-3.22,8-8.2C20,10.48,17.33,6.55,12,2z M12,20c-3.35,0-6-2.57-6-6.2 c0-2.34,1.95-5.44,6-9.14c4.05,3.7,6,6.79,6,9.14C18,17.43,15.35,20,12,20z M7.83,14c0.37,0,0.67,0.26,0.74,0.62 c0.41,2.22,2.28,2.98,3.64,2.87c0.43-0.02,0.79,0.32,0.79,0.75c0,0.4-0.32,0.73-0.72,0.75c-2.13,0.13-4.62-1.09-5.19-4.12 C7.01,14.42,7.37,14,7.83,14z" />
    </svg>
  );
}
export function SunIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      viewBox="0 96 960 960"
    >
      <path d="M450 286V136h60v150h-60Zm256 106-42-42 106-107 42 43-106 106Zm64 214v-60h150v60H770Zm-320 410V866h60v150h-60ZM253 391 148 286l42-42 106 106-43 41Zm518 517L664 802l41-41 108 104-42 43ZM40 606v-60h150v60H40Zm151 302-43-42 105-105 22 20 22 21-106 106Zm289-92q-100 0-170-70t-70-170q0-100 70-170t170-70q100 0 170 70t70 170q0 100-70 170t-170 70Zm0-60q75 0 127.5-52.5T660 576q0-75-52.5-127.5T480 396q-75 0-127.5 52.5T300 576q0 75 52.5 127.5T480 756Zm0-180Z" />
    </svg>
  );
}
export function RubbishIcon(props) {
  let color = getFill(props.color);
  let size = changeSize(props.size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      viewBox="0 0 24 24"
      fill="#000000"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z" />
    </svg>
  );
}
