export const colors = {
  farmColor: "red",
  fieldColor: "purple",
  placeColor: "red",
  deviceColor: "purple",
  cropColor: "lightBlue",
  cropHistoryColor: "darkBlue",
  fertiliserColor: "yellow",
  fertiliserHistoryColor: "orange",
  yieldsColor: "cyan",
  soilReportsColor: "blue",
  interactionReportsColor: "blue",
};

export const buttonConfig = {
  red: {
    bgColor: "bg-red-500 ",
    color: "text-red-500 ",
    border: "border-red-500 ",
    fill: "fill-red-500",
  },
  amber: {
    bgColor: "bg-amber-500 ",
    color: "text-amber-500 ",
    hoverFill: "hover:fill-amber-500 ",
    fill: "fill-amber-500",
  },
  cyan: {
    bgColor: "bg-cyan-500 ",
    color: "text-cyan-500 ",
    hoverFill: "hover:fill-cyan-500 ",
    fill: "fill-cyan-500",
  },
  black: {
    bgColor: "bg-black ",
    color: "text-black ",
    border: "border-black ",
    fill: "fill-black",
  },
  white: {
    bgColor: "bg-white ",
    color: "text-white ",
    border: "border-white ",
    fill: "fill-white",
  },
  purple: {
    bgColor: "bg-purple-500 ",
    color: "text-purple-500 ",
    border: "border-purple-500 ",
    fill: "fill-purple-500",
  },
  contrastPurple: {
    bgColor: "bg-[#00bfa6] ",
    color: "text-[#5908A6] ",
    border: "border-[#5908A6] ",
    fill: "fill-[#5908A6]",
  },
    lightBlue: {
      bgColor: "bg-[#007ACC]",
      color: "text-[#007ACC]",
      border: "border-[#007ACC]",
      fill: "fill-[#007ACC]",
      hex: "#007ACC",
    },
    murkyBlue: {
      bgColor: "bg-[#1A4F85]",
      color: "text-[#1A4F85]",
      border: "border-[#1A4F85]",
      fill: "fill-[#1A4F85]",
      hex: "#1A4F85",
    },
    darkBlue: {
      bgColor: "bg-[#333333]",
      color: "text-[#333333]",
      border: "border-[#333333]",
      fill: "fill-[#333333]",
      hex: "#333333",
    },
   teal: {
    bgColor: "bg-teal-700 ",
    color: "text-teal-700 ",
    border: "border-teal-700 ",
    fill: "fill-teal-700",
  },
  yellow: {
    bgColor: "bg-yellow-500 ",
    color: "text-yellow-500 ",
    border: "border-yellow-500 ",
    fill: "fill-yellow-500",
  },
  orange: {
    bgColor: "bg-orange-500 ",
    color: "text-orange-500 ",
    border: "border-orange-500 ",
    fill: "fill-orange-500",
  },
  pink: {
    bgColor: "bg-pink-500 ",
    color: "text-pink-500 ",
    border: "border-pink-500 ",
    fill: "fill-pink-500",
  },
  blue: {
    bgColor: "bg-blue-500 ",
    color: "text-blue-500 ",
    border: "border-blue-500 ",
    fill: "fill-blue-500",
  },
  disabled: {
  bgColor: "bg-[#F1F1F2]",
  color: "text-[#B5B5B5]",
  },
};
