import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { TransitionGroup } from "semantic-ui-react";

import {
  DashboardIcon,
  FarmIcon,
  MenuIcon,
  RecommendationIcon,
  CalendarIcon,
  BuildingIcon,
  InteractionIcon,
} from "../Icons";
import SideMenu from "../SideMenu";
import { buttonConfig } from "../Styles";

export default function Footer() {
  const [isMenu, setMenu] = React.useState(false);
  const navigate = useNavigate();

  const color = buttonConfig.lightBlue;

  const menuRef = useRef(null);

  const active = (path) => location.pathname === path;

  const colors = {
    dashboard: !isMenu && active("/dashboard") ? "contrastPurple" : "white",
    inputs: !isMenu && active("/") ? "contrastPurple" : "white",
    mixes: !isMenu && active("/mixes") ? "contrastPurple" : "white",
    recommendations:
      !isMenu && active("/recommendation") ? "contrastPurple" : "white",
    menu: isMenu ? "contrastPurple" : "white",
  };

  return (
    <>
      <footer
        className={
          "sm:hidden sticky bottom-0 w-screenw z-50 py-1 pb-2 " + color.bgColor
        }
      >
        <BottomNavigation sx={{ bgcolor: color.hex }} showLabels>


          <BottomNavigationAction
            className="!transition-all !duration-300 !ease-in-out !mb-0"
            icon={<InteractionIcon color={colors.recommendations} />}
            onClick={() => navigate("/recommendation")}
            label={
              <p className={buttonConfig[colors.recommendations].color}>
                Interactions
              </p>
            }
          />
            <BottomNavigationAction
            className="!transition-all !duration-300 !ease-in-out"
            icon={<CalendarIcon color={colors.mixes} />}
            onClick={() => navigate("/mixes")}
            label={
              <p className={"whitespace-nowrap " + buttonConfig[colors.mixes].color}>
                Calendar
              </p>
            }
          />

          
          <BottomNavigationAction
            className="!transition-all !duration-300 !ease-in-out"
            icon={
              <DashboardIcon
                isActive={active("/dashboard")}
                color={colors.dashboard}
              />
            }
            onClick={() => navigate("/dashboard")}
            label={
              <p className={buttonConfig[colors.dashboard].color}>Dashboard</p>
            }
          />

          
 {/*
 <BottomNavigationAction
            className="!transition-all !duration-300 !ease-in-out"
            icon={<BuildingIcon color={colors.inputs}  />}
            onClick={() => navigate("/")}
            label={
              <p className={"whitespace-nowrap " + buttonConfig[colors.inputs].color}>
                Places
              </p>
            }
          />
*/}

          <BottomNavigationAction
            ref={menuRef}
            className="!transition-all !duration-300 !ease-in-out"
            icon={<MenuIcon color={colors.menu} />}
            onClick={() => setMenu(!isMenu)}
            label={<p className={buttonConfig[colors.menu].color}>Menu</p>}
          />

        </BottomNavigation>


      </footer>

      <TransitionGroup animation="fade left" duration={300}>
        {isMenu && (
          <div className="fixed top-0 right-0">
            <SideMenu isOpenMethod={setMenu} menuRef={menuRef} />
          </div>
        )}
      </TransitionGroup>
    </>
  );
}
