import { EventType } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { motion } from "framer-motion";
import { Suspense, useEffect, useState } from "react";
import PWAPrompt from "react-ios-pwa-prompt";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { DimmerDimmable } from "semantic-ui-react";

import Footer from "../components/MainFormTabs/Footer";
import PageHeader from "../components/PageHeader";
import EULAPopup from "../components/Popups/EULAPopup";
import SignInForm from "../components/SignInForm";
import SiteHeader from "../components/SiteHeader";
import { AccessControlContext } from "../contexts/AccessControlContext";
import authenticateFetch from "../utils/authenticateFetch";

// TODO(Callum): Move authenticateFetch calls out of componentDidMount functions into the react router loader
// Then add the custom page loader
const Loader = (
  <div className="flex justify-center items-center">
    <div role="status">
      <svg
        aria-hidden="true"
        className="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

const slide = {
  hidden: {
    x: "0",
    opacity: 0.2,
  },
  visible: {
    x: 0,
    opacity: 1,
  },
};

export default function (props) {
  const { pathname } = useLocation();
  const { instance } = useMsal();

  const [popupOpen, setPopupOpen] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [accessControl, setAccessControl] = useState();

  const popupChannel = new BroadcastChannel("popupChannel");
  popupChannel.onmessage = (e) => {
    if (window.matchMedia("(max-height: 820px)").matches) setPopupOpen(e.data);
  };

  const signEULA = async () => {
    const res = await authenticateFetch(
      instance,
      "/api/v1/settings/accesscontrol",
      {
        method: "GET",
        headers: new Headers(),
      }
    );

    const json = await res.json();
    setAccessControl(json) || setIsLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    instance.addEventCallback((event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload.account
      ) {
        authenticateFetch(instance, "/api/v1/settings/accesscontrol", {
          method: "GET",
          headers: new Headers(),
        })
          .then((res) => res.json())
          .then((ac) => setAccessControl(ac) || setIsLoading(false));
      }
    });
  }, [instance]);

  function iOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }
  let isOnIOS = iOS(); //Not used currently. Need a way to detect if it is a user's first visit

  if (!isLoading && !accessControl.RecentEULASigned) {
    return <EULAPopup callback={signEULA} />;
  }

  return (
    <AccessControlContext.Provider value={{ accessControl, isLoading }}>
      <AuthenticatedTemplate>
        {/* <PWAPrompt
          debug={isOnIOS}
          copyBody="This website has app functionality. Add it to your home screen to use it in fullscreen and while offline. Only available on Safari."
        /> */}
        <DimmerDimmable className={popupOpen}>
          <div className="background">
            <div className="responsive-wrapper">
              <div className="min-h-[calc(100vh_-_56px)] mb-32 sm:mb-96 sm:min-h-full">
                <SiteHeader />
                <PageHeader />
                <motion.div
                  key={pathname}
                  transition={{
                    x: { duration: 0.2 },
                    default: { ease: "easeIn" },
                  }}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={slide}
                >
                  {props.error !== undefined && props.error}
                  {isLoading ? (
                    Loader
                  ) : (
                    <>
                      {accessControl.RecentEULASigned ? (
                        <Suspense fallback={Loader}>
                          <Outlet />
                        </Suspense>
                      ) : (
                        <EULAPopup callback={signEULA} />
                      )}
                    </>
                  )}
                </motion.div>
              </div>
              <Footer />
            </div>
          </div>
        </DimmerDimmable>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {pathname === "/" && <SignInForm />}
        {pathname !== "/" && <Navigate to="/" replace={true} />}
      </UnauthenticatedTemplate>
    </AccessControlContext.Provider>
  );
}
