import { Card, Grid, Image } from "semantic-ui-react";
import "./SignInForm.css";

import SignInButton from "../components/Buttons/SignInButton";

function SignInForm(props) {
  return (
    <div className="bg-[#CCCCCC] min-h:100vh">
      <Grid
        textAlign="center"
        style={{ height: "100vh" }}
        verticalAlign="middle"
      >
        <Grid.Column
          as={Card}
          className="!max-w-[66vw] !sm:max-w-[85vw] !min-h-[66vh] signin-card"
        >
          <Grid
            columns={2}
            stackable
            relaxed
            textAlign="center"
            container
            className="columns"
          >
            <Grid.Column className="column-left"></Grid.Column>

            <Grid.Column className="column-right">
              <Image
                src="/img/Intermentis-1600px.png"
                alt="Intermentis Logo"
              />
              <SignInButton />
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid>
    </div>
  );
}

export default SignInForm;
