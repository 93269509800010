/*import requestAccessToken from "./requestAccessToken";

TODO GP 
// Appends the appropriate header before fetching from API


export default async function (
  instance,
  endpoint,
  options,
  params = null,
  cacheFirst = false
) {
  const token = await requestAccessToken(instance);
  options.headers.append("Authorization", `Bearer ${token}`);

  if (params) {
    endpoint += "?" + new URLSearchParams(params).toString();
  }

  if (cacheFirst) {
    const match = await caches.match(endpoint);
    if (match) return match;
  }

  return fetch(endpoint, options);
}
*/
import requestAccessToken from "./requestAccessToken";

// Appends the appropriate header before fetching from API
export default async function (instance, endpoint, options, params = null, cacheFirst = false) {
  try {
    if (process.env.BYPASS_AUTH_FETCH === 'true') {
      console.log("Authentication fetch bypassed");
      if (!options.headers) {
        options.headers = new Headers();
      }
      options.headers.append("Authorization", `Bearer bypassed-token`);
    } else {
      console.log("Requesting access token...");
      const token = await requestAccessToken(instance);
      console.log("Access token acquired:", token);

      if (!options.headers) {
        options.headers = new Headers();
      }
      options.headers.append("Authorization", `Bearer ${token}`);
      console.log("Authorization header set");
    }

    if (params) {
      endpoint += "?" + new URLSearchParams(params).toString();
      console.log("Endpoint with query params:", endpoint);
    }

    if (cacheFirst) {
      console.log("Checking cache for endpoint:", endpoint);
      const match = await caches.match(endpoint);
      if (match) {
        console.log("Cache hit, returning cached response");
        return match;
      } else {
        console.log("Cache miss, proceeding with fetch");
      }
    }

    console.log("Fetching from endpoint:", endpoint);
    const response = await fetch(endpoint, options);

    if (!response.ok) {
      const error = new Error(`HTTP error! status: ${response.status}`);
      console.error("Fetch error:", error);
      throw error;
    }

    console.log("Fetch successful, returning response");
    return response;
  } catch (error) {
    console.error("Error in authenticateFetch:", error);
    throw error;
  }
}
