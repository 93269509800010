export default function PingingCircle(props) {
  let size = " h-7 w-7 ";
  let position = " -top-3 -right-3 ";
  if (props.size !== undefined) {
    switch (props.size) {
      case "small":
        size = " h-4 w-4 text-sm";
        position = " -top-[3px] -right-[3px] ";
        break;
      case "medium":
        size = " h-6 w-6 ";
        position = " -top-3 -right-3 ";
        break;

      default:
        break;
    }
  }

  return (
    <span className={"flex absolute " + size + position}>
      <span
        className={
          "animate-ping absolute inline-flex  rounded-full bg-red-400 opacity-75" +
          size
        }
      ></span>
      <span
        className={
          "relative inline-flex rounded-full flex justify-center items-center text-white   bg-red-500" +
          size
        }
      >
        <p>{props.content}</p>
      </span>
    </span>
  );
}
