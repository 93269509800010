import { useMsal } from "@azure/msal-react";
import { Button } from "semantic-ui-react";

import { loginRequest } from "../../authConfig";

const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = (loginType) => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  };

  return (
    <Button fluid positive className="!mt-10" onClick={() => handleLogin()}>
      Sign in
    </Button>
  );
};

export default SignInButton;
