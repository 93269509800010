import { openDB } from "idb";

const NAME = "Notifications";
const VERSION = 1;
const STORE = "notifications";

const badgeChannel = new BroadcastChannel("notifications/badge");
badgeChannel.addEventListener("message", async (e) => {
  if ("setAppBadge" in navigator && "clearAppBadge" in navigator) {
    const db = await open();
    const tx = await db.transaction(STORE, "readwrite");
    const count = await tx.store.count();

    if (count === 0) {
      navigator.clearAppBadge();
    } else {
      navigator.setAppBadge(count);
    }
  }
});

async function open() {
  const db = await openDB(NAME, VERSION, {
    upgrade(db) {
      const store = db.createObjectStore(STORE, {
        keyPath: "id",
        autoIncrement: true,
      });

      store.createIndex("date", "date");
    },
  });

  return db;
}

async function updateBadge() {
  const broadcast = new BroadcastChannel("notifications/badge");
  broadcast.postMessage("update");
}

export async function addObj(obj) {
  const db = await open();

  return db.add(STORE, { ...obj, date: new Date() }).then(() => updateBadge());
}

export async function deleteObj(id) {
  const db = await open();

  return await db.delete(STORE, id).then(() => updateBadge());
}

export async function getAll() {
  const db = await open();

  return await db.getAllFromIndex(STORE, "date");
}
