import { useRouteError } from "react-router-dom";
import { Header } from "semantic-ui-react";

import Page from "../models/Page";

export default function () {
  const err = useRouteError();
  console.error(err);

  return (
    <Page
      error={
            <div className="text-center mt-32 flex flex-col justify-center">
            <Header size="huge">{`${err.status ?? ""} Error`}</Header>
            <p className="text-xl">{err.statusText ?? "An unexpected error occurred."}</p>
            {err.message && <p className="text-lg">Message: {err.message}</p>}
            {err.stack && <pre className="text-sm text-left">{err.stack}</pre>}
            <p>Something went wrong.</p>
            </div>
      }
    />
  );
}

/*
        <div className="text-center mt-32 flex flex-col justify-center ">
          <Header size="huge">{`${err.status ?? ""} Error`}</Header>
          <p className="text-xl">{err.statusText}</p>
          Something went wrong.
        </div>

*/