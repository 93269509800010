import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";

import authenticateFetch from "../utils/authenticateFetch";
import createBusinessStyle from "../utils/createBusinessStyle";
import ResentPopup from "./Popups/ResentPopup";
import { buttonConfig } from "./Styles";

export default function () {
  const { instance } = useMsal();
  const [business, setBusiness] = useState(false);

  let text;
  const pathname = window.location.pathname;

  switch (pathname) {
    case "/records":
      text = "Records";
      break;

    case "/settings":
      text = "Settings";
      break;

    case "/notifications":
      text = "Notifications";
      break;

      case "/notifications/interaction":
        text = "Interaction";
        break;

    case "/recommendation":
      text = "Recommend";
      break;

    case "/mixes":
      text = "Mixes";
      break;

    case "/feedback":
      text = "Feedback";
      break;

    case "/":
      text = "Places";
      break;

    case "/dashboard":
      text = "Dashboard";
      break;

    case "/suppliers":
      text = "Suppliers";
      break;

    case "/business":
      text = "Business";
      break;

    case "/consultant":
      text = "Consultant";
      break;

    default:
      text = "";
      break;
  }

  useEffect(() => {
    authenticateFetch(instance, "/api/v1/database/usersbusiness", {
      method: "GET",
      headers: new Headers(),
    })
      .then((res) => res.json())
      .then(
        (business) =>
          setBusiness(business) || createBusinessStyle(business.BusinessCSSUrl)
      );
  }, [instance]);

  return (
    <div
      className={
        " h-10 p-[0.4rem] mb-8 flex  relative justify-between items-center sm:hidden bg-white s" +
        buttonConfig.darkBlue.bgColor
      }
    >
      {business && (
        <img 
          onClick={() => window.open(business.BusinessURL, "_blank")}
          src={business.BusinessLogoURL}
          id="BusinessLogoMobile"
          alt={`${business.BusinessName} logo`}
          className="hover:cursor-pointer"
        />
      )}
      <div className="absolute">
        <ResentPopup />
      </div>
      <h1 className="text-3xl text- font-bold pr-5">{text}</h1>
    </div>
  );
}
