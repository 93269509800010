import authenticateFetch from "./authenticateFetch";

export default function (req, instance) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const resentStatus = new BroadcastChannel("resentStatus");

  const URL = req.URL;
  delete req["URL"];
  delete req["ID"];

  authenticateFetch(instance, URL, {
    method: "POST",
    headers,
    body: JSON.stringify(req),
  })
    .then((res) => {
      resentStatus.postMessage(true);
      console.log("Successfully Resent");
    })
    .catch((err) => {
      resentStatus.postMessage(false);
      console.log("Resend failed");
    });
}
